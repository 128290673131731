import React, { useContext } from "react";
import { ApolloError } from "@apollo/client";
import { UserFragment as User } from "../../../graphql/generated/generated";

export interface AuthContextType {
  isAuthenticated: boolean;
  paid?: boolean;
  isLoading: boolean;
  user?: User;
  error?: ApolloError;
  checkAuthentication: () => void;
  logout: () => void;
  login: (u: User) => void;
  updateUser: (u: User) => void;
}

const voidFunction = () => {};

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  checkAuthentication: () => {},
  logout: voidFunction,
  login: (user: User) => {},
  updateUser: (user: User) => {},
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
