import { gql } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client";
import { MALUKI_USER_DETAILS } from "../../lib/context/AuthContext/AuthContextProvider";

export const REFRESH_JWT_AUTH_TOKEN = gql`
  mutation refreshJwtAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(
      input: {
        clientMutationId: "refresh-token-mutation"
        jwtRefreshToken: $refreshToken
      }
    ) {
      authToken
      clientMutationId
    }
  }
`;

const logout = () => {
  localStorage.removeItem(MALUKI_USER_DETAILS);
  window.location.reload();
};

// TODO: Refactor the use of getNewTokenQuery variable, to moved inside the function or a file
let getNewTokenQuery: Promise<any>;
let isTokenNewQueryResolving: Boolean = false;

export const getNewToken = async () => {
  const token = localStorage.getItem(MALUKI_USER_DETAILS);
  const jwtRefreshToken = token ? JSON.parse(token)?.jwtRefreshToken : null;

  if (!token || !jwtRefreshToken) {
    logout();
    return;
  }

  if (getNewTokenQuery && isTokenNewQueryResolving) {
    return getNewTokenQuery;
  }

  isTokenNewQueryResolving = true;

  try {
    const res = await fetch(process.env.REACT_APP_GRAPHQL as RequestInfo, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        variables: {
          refreshToken: jwtRefreshToken,
        },
        query: REFRESH_JWT_AUTH_TOKEN.loc?.source.body,
      }),
    });
    const resNew = await res.json();
    isTokenNewQueryResolving = false;
    return resNew.data.refreshJwtAuthToken.authToken;
  } catch (error) {
  } finally {
    isTokenNewQueryResolving = false;
  }
  return getNewTokenQuery;
};

let hasExpiredTokenError = false;
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const token = localStorage.getItem(MALUKI_USER_DETAILS);
    const jwtRefreshToken = token ? JSON.parse(token)?.jwtRefreshToken : null;

    if (!token || !jwtRefreshToken) {
      // logout();
      return;
    }

    const authToken = JSON.parse(token);

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        let errM = err as any;
        if (
          errM.debugMessage &&
          errM.debugMessage.indexOf("invalid-jwt") >= 0 &&
          !hasExpiredTokenError
        ) {
          hasExpiredTokenError = true;
          return fromPromise(
            getNewToken().catch(() => {
              logout();
              return;
            })
          ).flatMap((accessToken): any => {
            if (accessToken && accessToken.length > 0) {
              localStorage.setItem(
                MALUKI_USER_DETAILS,
                JSON.stringify({
                  jwtAuthToken: accessToken,
                  jwtRefreshToken: authToken.jwtRefreshToken,
                })
              );
              window.location.reload();
            }
            return forward(operation);
          });
        }
        if (err?.message === "You have been login to another device") {
          logout();
          return;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);
