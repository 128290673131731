import { createStyles, makeStyles } from '@material-ui/core/styles';
import logoNew from '../../assets/images/logo-new.jpg';

const useStyles = makeStyles((theme) => {
  return createStyles({
    wrapper: {
      maxWidth: 1600,
      minHeight: '100vh',
      margin: '0 auto',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      textAlign: 'center',
      padding: '0 20px',

      '& h1': {
        fontSize: '2.5rem',
        fontWeight: 700,
      },
      '& p': {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.5,
        maxWidth: 800,
      },
      '& a': {
        color: '#7b6afd',
      },
      '& a:hover': {
        color: theme.palette.primary.main,
      },

      '& img, & video': {
        width: '400px',
        objectFit: 'cover',
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
          width: '80%',
        },
      },
    },
  });
});
export const CommingSoon = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img src={logoNew} alt="Maluki Accounting" />
      <h1>Maluki Accounting | Web Coming Soon</h1>
      <p>
        We’re excited to reveal our new branding soon! Stay tuned for our
        refreshed look and enhanced experience. For quick information, contact
        us at{' '}
        <a href="mailto:info@malukiaccounting.com">info@malukiaccounting.com</a>{' '}
        or call <a href="tel:+383 46 333 444">+383 46 333 444</a> (CEST). Thank
        you for your understanding.
      </p>
    </div>
  );
};
