import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { client } from "apollo/client";
import { MuiThemeProvider } from "@material-ui/core";
import MainTheme from "./styles/theme/MainTheme";

/****
 * * IMPORTANT NOTE: Do not change _main.scss_ import order.
 * It needs to be imported before main App component
 ****/
import "./styles/main.scss";

// import App from "./App";
import { CommingSoon } from "components/commingSoon/CommingSoon";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={MainTheme}>
          <CommingSoon /> 
          {/* <App /> */}
        </MuiThemeProvider>
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
