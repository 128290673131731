import React, { useState, useEffect } from 'react';
import { writeStorage } from '@rehooks/local-storage';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import { useRouter } from 'lib/hooks/useRouter';
import { useApolloClient } from '@apollo/client';

import { AuthContextType, AuthContext } from './AuthContext';
import {
  GetViewerQueryDocument,
  GetViewerQueryQueryResult,
  GetViewerQueryQueryVariables,
  UserFragment as User,
} from '../../../graphql/generated/generated';
import { AppLoader } from 'components/shared/AppLoader/AppLoader';

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export const MALUKI_USER_DETAILS = 'MALUKI_USER_DETAILS';

interface LocalStorageDataType {
  jwtAuthToken: string;
  jwtRefreshToken: string;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userStorageDetails] = useLocalStorage<LocalStorageDataType>(
    MALUKI_USER_DETAILS
  );

  useEffect(() => {
    (async () => {
      await checkAuthentication();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    if (!userStorageDetails) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const res = await apolloClient.query<
        GetViewerQueryQueryVariables,
        GetViewerQueryQueryResult
      >({
        query: GetViewerQueryDocument,
        fetchPolicy: 'network-only',
      });

      if (res?.data?.viewer) {
        login(res?.data?.viewer as User);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = (user: User) => {
    const { jwtAuthToken, jwtRefreshToken, ...restUserData } = user;
    setUser(restUserData);
  };

  const login = (user: User) => {
    const { jwtAuthToken, jwtRefreshToken } = user;
    writeStorage(
      MALUKI_USER_DETAILS,
      JSON.stringify({ jwtAuthToken, jwtRefreshToken })
    );
    setUser(user);
  };

  const logout = async () => {
    setUser(undefined);
    await apolloClient.clearStore();
    deleteFromStorage(MALUKI_USER_DETAILS);

    // Finally we redirect to login page (optional)
    router.history.push('/login');
  };

  if (loading) {
    return <AppLoader />;
  }

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    paid: Boolean(user?.userOptions?.membershipExpireDate),
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    logout: logout,
    updateUser: updateUser,
    checkAuthentication,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
